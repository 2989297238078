import React from "react"

import MainNavBar from "../mainNavBar"
import CaptionTerms from "./captionTerms"

const HeaderTerms = ({ siteTitleContact }) => (
  <header className="headerTerms">
    <a href="tel:18003582468" className="topRightPhone">1-800-358-2468</a>
    <MainNavBar />
    <CaptionTerms />
  </header>
)

export default HeaderTerms
