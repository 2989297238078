import React from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const CaptionTerms = () => (
  <Container>
        <Row>
            <Col>
                <div className="captionTerms">
                    <h1>LifeSync Corporation</h1>
                    <p>Standard Terms and Conditions</p>
                </div>
            </Col>
        </Row>
  </Container>
)


export default CaptionTerms
